@charset "utf-8";

@font-face {
    font-family: 'League Spartan';
    src: url('../assets/leaguespartan-bold.eot');
    src: url('../assets/leaguespartan-bold.eot?#iefix') format('embedded-opentype'),
         url('../assets/leaguespartan-bold.woff2') format('woff2'),
         url('../assets/leaguespartan-bold.woff') format('woff'),
         url('../assets/leaguespartan-bold.ttf') format('truetype'),
         url('../assets/leaguespartan-bold.svg#league_spartanbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

// Our variables
$base-font-family: "League Spartan", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

/* Main Primary color */
$text-color:       #292929;
$background-color: #FFFFFF;
$brand-color:      #1E55AD;
$grey-color:       #52626E;
$grey-color-light: #065187;
$grey-color-dark:  #494949;

/* Main Secondary color */
$text-color-secondary: #404040;
$background-color-secondary: #FFF9E7;
$brand-color-secondary: #FFD117;
$grey-color-secondary: #ACA07D;
$grey-color-light-secondary: #D29C00;
$grey-color-dark-secondary: #404040;

/* Main Tertiary color */
$text-color-tertiary: #404040;
$background-color-tertiary: #FFEDE7;
$brand-color-tertiary: #FF6931;
$grey-color-tertiary: #AC897D;
$grey-color-light-tertiary: #D23900;
$grey-color-dark-tertiary: #404040;

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

.color-primary-0 { color: #1E55AD }	/* Main Primary color */
.color-primary-1 { color: #6189C9 }
.color-primary-2 { color: #3D6CB7 }
.color-primary-3 { color: #0D3F8F }
.color-primary-4 { color: #383F49 }

.color-secondary-1-0 { color: #FFD117 }	/* Main Secondary color (1) */
.color-secondary-1-1 { color: #FFE26E }
.color-secondary-1-2 { color: #FFDA44 }
.color-secondary-1-3 { color: #D8AD00 }
.color-secondary-1-4 { color: #6F6952 }

.color-secondary-2-0 { color: #FF7517 }	/* Main Secondary color (2) */
.color-secondary-2-1 { color: #FFA96E }
.color-secondary-2-2 { color: #FF9044 }
.color-secondary-2-3 { color: #D85700 }
.color-secondary-2-4 { color: #6F5D52 }